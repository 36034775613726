<template>
  <main>
    <div class="bg1">
      <img v-lazy="require('./yjzdsc_img/banner.webp')" alt="" class="banner" />
      <span class="yjzdsc">游记自动生成</span>
      <span class="fh1">做生活的导演，记录旅行的足迹</span>
    </div>
    <div class="bg2">
      <div class="img11">
        <img v-lazy="require('./yjzdsc_img/bg1.webp')" class="image1" />
      </div>
      <span class="qrqm">千人千面</span>
      <span class="fh2"
        >通过建设智能素材库，使每个游客的作品都具有独特性，实现“千人千面“的效果</span
      >
    </div>
    <div class="bg3">
      <span class="cpys">产品优势</span>
      <div class="box1">
        <div class="img12">
          <img v-lazy="require('./yjzdsc_img/img1.webp')" alt="" class="img1" />
        </div>
        <span class="jllx">记录旅行，支持离线</span>
        <span class="fh3"
          >通过拍照、留言和轨迹追踪，完整记录你的旅行足迹，珍藏旅途中的点点滴滴。支持离线模式，无需担心手机流量</span
        >
      </div>
      <div class="box2">
        <div class="img22">
          <img v-lazy="require('./yjzdsc_img/img2.webp')" alt="" class="img2" />
        </div>
        <span class="zpwz">给相机照片添加位置信息</span>
        <span class="fh4"
          >将数码相机照片上传到有轨迹追踪的行程中，会通过匹配照片的拍摄时间，自动给照片加上位置标签，再也不会忘记照片的拍摄地点了</span
        >
      </div>
      <div class="box3">
        <div class="img33">
          <img v-lazy="require('./yjzdsc_img/img3.webp')" class="img3" />
        </div>
        <span class="scyj">生成游记，平台分发</span>
        <span class="fh5"
          >自动将旅途足迹整理成精美游记，并能像电影一样在地图上动画回放。把精彩内容与朋友分享</span
        >
      </div>
    </div>
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.bg1 {
  height: 600px;
  // background-image: url(./yjzdsc_img/banner.png);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 600px;
  }
  .yjzdsc {
    min-width: 309px;
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .fh1 {
    min-width: 675px;
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 600px;
  position: relative;
  .img11 {
    position: absolute;
    top: 80px;
    left: 360px;
    width: 1200px;
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 420px;
      width: 100%;
    }
    .image1 {
      width: 1200px;
    }
  }
  .qrqm {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 240px;
    left: 878px;
  }
  .fh2 {
    min-width: 738px;
    height: 32px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 308px;
    left: 592px;
  }
}
.bg3 {
  // min-width: 1919px;
  height: 1328px;
  background: #f6f8fb;
  position: relative;
  .cpys {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 877px;
  }
  .box1 {
    min-width: 1200px;
    height: 324px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 360px;
    .img12 {
      width: 600px;
      height: 324px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 324px;
        width: 100%;
      }
      .img1 {
        width: 600px;
      }
    }
    .jllx {
      min-width: 297px;
      height: 45px;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 64px;
      left: 648px;
      text-align: left;
    }
    .fh3 {
      width: 504px;
      height: 96px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 133px;
      left: 648px;
      text-align: left;
    }
  }
  .box2 {
    min-width: 1200px;
    height: 324px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 550px;
    left: 360px;
    .img22 {
      width: 600px;
      height: 324px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 324px;
        width: 100%;
      }
      .img2 {
        width: 600px;
      }
    }
    .zpwz {
      min-width: 363px;
      height: 45px;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 64px;
      left: 48px;
      text-align: left;
    }
    .fh4 {
      width: 504px;
      height: 96px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 133px;
      left: 48px;
      text-align: left;
    }
  }
  .box3 {
    min-width: 1200px;
    height: 324px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 904px;
    left: 360px;
    .img33 {
      width: 600px;
      height: 324px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 324px;
        width: 100%;
      }
      .img3 {
        width: 600px;
      }
    }
    .scyj {
      min-width: 297px;
      height: 45px;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 64px;
      left: 648px;
      text-align: left;
    }
    .fh5 {
      width: 504px;
      height: 64px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 133px;
      left: 648px;
      text-align: left;
    }
  }
}
</style>
